import { applyMiddleware, combineReducers, createStore } from 'redux'

// reducers imports
import userReducer from './user/reducer'
import salesChannelReduces from './salesChannel/reducer'
import RulesReduces from './rules/reducer'

// middlewares import
import userMiddleware from './user/middleware'
import httpMiddleware from './http/middleware'
import salesChannelMiddleware from './salesChannel/middleware'
import RulesMiddleware from './rules/middleware'

const reducers = combineReducers({
  user: userReducer,
  salesChannel: salesChannelReduces,
  priceRules: RulesReduces,
})

const middlewares = applyMiddleware(
  httpMiddleware,
  userMiddleware,
  salesChannelMiddleware,
  RulesMiddleware
)

export default createStore(reducers, middlewares)

import initialState from './initialState'
import {
  PRICE_RULES,
  PRICE_RULES_SUCCESS,
  PRICE_RULES_FAILURE,
  CREATE_RULE,
  CREATE_RULE_SUCCESS,
  CREATE_RULE_FAILURE,
  RULE_DETAILS,
  RULE_DETAILS_SUCCESS,
  RULE_DETAILS_FAILURE,
  HIDE_ERRORS,
  UPDATE_RULE,
  UPDATE_RULE_SUCCESS,
  UPDATE_RULE_FAILURE,
  EVAL_RULE,
  EVAL_RULE_SUCCESS,
  EVAL_RULE_FAILURE,
  RESET_RULE,
  RESET_CREATED_RULE,
} from './actions'
export default (state = initialState(), action) => {
  if (action.type === PRICE_RULES) {
    return {
      ...state,
      isLoading: true,
    }
  }
  if (action.type === PRICE_RULES_SUCCESS) {
    const { data } = action.payload
    const mapping = data.map((a) => ({
      ...a,
      createdAt: a.createdAt ? a.createdAt.date.substring(0, 19) : a.createdAt,
      updatedAt: a.updatedAt ? a.updatedAt.date.substring(0, 19) : a.updatedAt,
    }))
    return {
      ...state,
      priceRules: mapping,
      isLoading: false,
      isFailed: false,
      errorMessage: '',
    }
  }
  if (action.type === PRICE_RULES_FAILURE) {
    const { ...error } = action.payload.data
    return {
      ...state,
      isLoading: false,
      isFailed: true,
      errorMessage: error.message,
    }
  }
  if (action.type === CREATE_RULE) {
    return {
      ...state,
      isLoading: true,
    }
  }
  if (action.type === CREATE_RULE_SUCCESS) {
    const { data } = action.payload
    /*const mapping = data.map((a) => ({
      ...a,
      createdAt: a.createdAt ? a.createdAt.date.substring(0, 19) : a.createdAt,
      updatedAt: a.updatedAt ? a.updatedAt.date.substring(0, 19) : a.updatedAt,
    }))*/
    return {
      ...state,
      createdRule: data,
      newRuleCreated: true,
      isLoading: true,
      isFailed: false,
      errorMessage: '',
    }
  }
  if (action.type === CREATE_RULE_FAILURE) {
    const { reasons } = action.payload
    return {
      ...state,
      isLoading: false,
      isFailed: true,
      errorMessage: reasons[0],
    }
  }
  if (action.type === RULE_DETAILS) {
    return {
      ...state,
      isLoading: true,
    }
  }
  if (action.type === RULE_DETAILS_SUCCESS) {
    const { data } = action.payload
    return {
      ...state,
      createdRule: data,
      isLoading: false,
      isFailed: false,
    }
  }
  if (action.type === RULE_DETAILS_FAILURE) {
    return {
      ...state,
      isLoading: false,
      isFailed: true,
    }
  }
  if (action.type === HIDE_ERRORS) {
    return {
      ...state,
      errorMessage: '',
    }
  }

  if (action.type === UPDATE_RULE) {
    return {
      ...state,
      isLoading: true,
    }
  }
  if (action.type === UPDATE_RULE_SUCCESS) {
    const { data } = action.payload
    return {
      ...state,
      createdRule: data,
      isLoading: false,
      isFailed: false,
      errorMessage: '',
    }
  }
  if (action.type === UPDATE_RULE_FAILURE) {
    const { reasons } = action.payload
    return {
      ...state,
      isLoading: false,
      isFailed: true,
      errorMessage: reasons.join('\n'),
    }
  }

  if (action.type === EVAL_RULE) {
    return {
      ...state,
      isLoading: true,
      isFailed: false,
      evalErrorMessage: '',
      eval: {},
    }
  }

  if (action.type === RESET_RULE) {
    return {
      ...state,
      isLoading: false,
      isFailed: false,
      evalErrorMessage: '',
      eval: {},
    }
  }

  if (action.type === EVAL_RULE_SUCCESS) {
    const { data } = action.payload
    console.log(action.payload)
    return {
      ...state,
      eval: data,
      isLoading: false,
      isFailed: false,
      evalErrorMessage: '',
    }
  }
  if (action.type === EVAL_RULE_FAILURE) {
    const { errors } = action.payload
    console.log('errors', errors)
    return {
      ...state,
      isLoading: false,
      isFailed: true,
      evalErrorMessage: errors,
    }
  }

  if (action.type === RESET_CREATED_RULE) {
    return {
      ...state,
      createdRule: {},
      newRuleCreated: false,
    }
  }

  return state
}

export const HTTP_FETCH = 'HTTP_FETCH'
export const HTTP_POST = 'HTTP_POST'
export const HTTP_PATCH = 'HTTP_PATCH'

export const httpPost = ({ endPoint, data, success, failure }) => ({
  type: HTTP_POST,
  payload: { endPoint, data, success, failure },
})

export const httpSuccess = (type, data) => ({
  type,
  payload: { data },
})

export const httpError = (type, { data }) => {
  return {
    type,
    payload: { ...data },
  }
}

export const httpFetch = ({ endPoint, success, failure }) => ({
  type: HTTP_FETCH,
  payload: { endPoint, success, failure },
})

export const httpPatch = ({ endPoint, data, success, failure }) => ({
  type: HTTP_PATCH,
  payload: { endPoint, data, success, failure },
})

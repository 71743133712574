export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGIN_GOOGLE_ACCOUNT = 'USER_LOGIN_GOOGLE_ACCOUNT'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE'

export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_FORGET_PASSWORD = 'USER_FORGET_PASSWORD'
export const USER_FORGET_PASSWORD_DONE = 'USER_FORGET_PASSWORD_DONE'
export const USER_FORGET_PASSWORD_SUCCESS = 'USER_FORGET_PASSWORD_SUCCESS'
export const USER_FORGET_PASSWORD_FAILURE = 'USER_FORGET_PASSWORD_FAILURE'

// login actions ---
export const login = (login, password) => ({
  type: USER_LOGIN,
  payload: { login, password },
})
export const googleLogin = (googleToken) => ({
  type: USER_LOGIN_GOOGLE_ACCOUNT,
  payload: { googleToken },
})
// logout actions ---
export const logout = () => ({
  type: USER_LOGOUT,
  payload: {},
})

// Forget password actions ---
export const forgetPassword = (mail) => ({
  type: USER_FORGET_PASSWORD,
  payload: { mail },
})

export const forgetPasswordDone = () => ({
  type: USER_FORGET_PASSWORD_DONE,
  payload: {},
})

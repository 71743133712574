import React from 'react'

import './Loading.scss'
import { CircularProgress } from '@material-ui/core'

export default function Loading() {
  return (
    <div className="Loading">
      <div className="spinner-border" role="status">
        <CircularProgress style={{ color: '#071E3A' }} />
      </div>
    </div>
  )
}

import { connect } from 'react-redux'
import Tests from '../components/Tests'
import { getEvalRule, isFailed, getEvalRuleError } from '../redux/rules/selectors'
import { evalRule, resetEval } from '../redux/rules/actions'

const mapStateToProps = (state) => ({
  getEvalRule: getEvalRule(state),
  isFailed: isFailed(state),
  getEvalRuleError: getEvalRuleError(state),
})

const mapDisptachToProps = (dispatch) => ({
  evalRule: (rule, value) => dispatch(evalRule(rule, value)),
  resetEval: () => dispatch(resetEval()),
})

export default connect(mapStateToProps, mapDisptachToProps)(Tests)

/* eslint-disable react/display-name */
import React from 'react'
import { Table, Button, Icon, Loading } from 'element-react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

export default function MuiDataTables(props) {
  const { salesChannel, priceRules, isLoading } = props
  const [inputs, setInputs] = React.useState([])
  var history = useHistory()

  React.useEffect(() => {
    // Should not ever set state during rendering, so do this in useEffect instead.
    const merge = salesChannel.map((item) =>
      Object.assign(
        {},
        item,
        ...priceRules.filter(
          (p) => p.canalVente === item.channel_code && p.canalVente !== null
        )
      )
    )
    setInputs(merge)
  }, [priceRules, salesChannel])
  console.log('inputs: ', inputs)
  function createRule(data) {
    history.push(`/create/${data.channel_name}`)
  }
  function editRule(data) {
    history.push(`/edit/${data.channel_name}/${data.ruleId}`, {
      channelCode: data.channel_code,
      newRule: false,
    })
  }

  const columns = [
    {
      label: 'Channel Name',
      prop: 'channel_name',
      sortable: true,
      render: function (data) {
        return (
          <span>
            <span style={{ marginLeft: '10px' }}>{data.channel_name}</span>
          </span>
        )
      },
    },
    {
      label: 'Rule Name',
      prop: 'name',
      sortable: true,
      render: function (data) {
        return (
          <span>
            <span style={{ marginLeft: '10px' }}>{data.name}</span>
          </span>
        )
      },
    },
    {
      label: 'Agent',
      prop: 'agent',
      sortable: true,
      render: function (data) {
        return (
          <span>
            <span style={{ marginLeft: '10px' }}>{data.agent}</span>
          </span>
        )
      },
    },
    {
      label: 'Created At',
      prop: 'createdAt',
      sortable: true,
      render: function (data) {
        return (
          <span>
            {data.createdAt ? <Icon name="time" /> : null}
            <span style={{ marginLeft: '10px' }}>{data.createdAt}</span>
          </span>
        )
      },
    },
    {
      label: 'Updated At',
      prop: 'updatedAt',
      sortable: true,
      render: function (data) {
        return (
          <span>
            <span style={{ marginLeft: '10px' }}>{data.updatedAt}</span>
          </span>
        )
      },
    },
    {
      label: 'Operations',
      render: function (data) {
        if (data.createdAt) {
          return (
            <span>
              <Button
                onClick={() => editRule(data)}
                plain={true}
                type="info"
                size="small"
              >
                Edit
              </Button>
            </span>
          )
        } else {
          return (
            <span>
              <Button
                onClick={() => createRule(data)}
                plain={true}
                type="info"
                size="small"
              >
                Create
              </Button>
            </span>
          )
        }
      },
    },
  ]
  return (
    <div style={{ paddingTop: '20px' }}>
      <Loading loading={isLoading}>
        <Table
          style={{ width: '100%' }}
          columns={columns}
          data={inputs}
          border={true}
          highlightCurrentRow={true}
          emptyText="No variables"
        />
      </Loading>
    </div>
  )
}

MuiDataTables.propTypes = {
  salesChannel: PropTypes.array.isRequired,
  priceRules: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

import initialState from './initialState'
import {
  USER_FORGET_PASSWORD,
  USER_FORGET_PASSWORD_FAILURE,
  USER_FORGET_PASSWORD_DONE,
  USER_FORGET_PASSWORD_SUCCESS,
  USER_LOGIN,
  USER_LOGIN_FAILURE,
  USER_LOGIN_GOOGLE_ACCOUNT,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
} from './actions'

export default (state = initialState(), action) => {
  if (action.type === USER_LOGIN) {
    return {
      ...state,
      collaborateur: {},
      token: null,
      isLoading: true,
    }
  }
  if (action.type === USER_LOGIN_SUCCESS) {
    const { collaborateur, value } = action.payload.data.Response
    return {
      ...state,
      collaborateur,
      token: value,
      isLoading: false,
      isLoggedIn: true,
      loginError: null,
    }
  }
  if (action.type === USER_LOGIN_FAILURE) {
    const { message } = action.payload
    return {
      ...state,
      collaborateur: {},
      token: null,
      isLoading: false,
      loginError: message,
    }
  }
  if (action.type === USER_LOGOUT) {
    return {
      ...state,
      collaborateur: {},
      token: null,
      isLoading: false,
      isLoggedIn: false,
    }
  }
  if (action.type === USER_LOGIN_GOOGLE_ACCOUNT) {
    return {
      ...state,
      isLoading: true,
      isLoggedIn: false,
    }
  }
  if (action.type === USER_FORGET_PASSWORD) {
    return {
      ...state,
      isLoading: true,
      forgetPassword: {},
    }
  }
  if (action.type === USER_FORGET_PASSWORD_DONE) {
    return {
      ...state,
      forgetPassword: {},
      isLoading: false,
      isLoggedIn: false,
      loginError: null,
    }
  }
  if (action.type === USER_FORGET_PASSWORD_SUCCESS) {
    return {
      ...state,
      forgetPassword: action.payload.data,
      isLoading: false,
      isLoggedIn: false,
    }
  }
  if (action.type === USER_FORGET_PASSWORD_FAILURE) {
    return {
      ...state,
      forgetPassword: action.payload.data,
      isLoading: false,
      isLoggedIn: false,
    }
  }

  return state
}

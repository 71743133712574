import React from 'react'
import PropTypes from 'prop-types'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import ProtectedRoute from './ProtectedRoute'
import Login from '../containers/Login'
import Home from '../containers/Home'
import Rules from '../containers/Rules'
import ForgetPassword from '../containers/ForgetPassword'
import NotFound from '../pages/NotFound'

export default function Routes({ isLoggedIn }) {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/forgetPassword" component={ForgetPassword} />
        <ProtectedRoute
          isLoggedIn={isLoggedIn}
          path={['/home', '/']}
          exact
          component={Home}
        />

        <ProtectedRoute
          isLoggedIn={isLoggedIn}
          path={['/edit/:channelName/:ruleId', '/create/:channelName']}
          exact
          component={Rules}
        />
        {/*When indicationg a route without path, it will always be rendered, so we may use it for non valid urls */}
        <Route component={NotFound} />
      </Switch>
    </Router>
  )
}
Routes.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
}

export const PRICE_RULES = 'PRICE_RULES'
export const PRICE_RULES_SUCCESS = 'PRICE_RULES_SUCCESS'
export const PRICE_RULES_FAILURE = 'PRICE_RULES_FAILURE'
export const CREATE_RULE = 'CREATE_RULE'
export const CREATE_RULE_SUCCESS = 'CREATE_RULE_SUCCESS'
export const CREATE_RULE_FAILURE = 'CREATE_RULE_FAILURE'
export const RULE_DETAILS = 'RULE_DETAILS'
export const RULE_DETAILS_SUCCESS = 'RULE_DETAILS_SUCCESS'
export const RULE_DETAILS_FAILURE = 'RULE_DETAILS_FAILURE'
export const UPDATE_RULE = 'UPDATE_RULE'
export const UPDATE_RULE_SUCCESS = 'UPDATE_RULE_SUCCESS'
export const UPDATE_RULE_FAILURE = 'UPDATE_RULE_FAILURE'
export const HIDE_ERRORS = 'HIDE_ERRORS'
export const EVAL_RULE = 'EVAL_RULE'
export const EVAL_RULE_SUCCESS = 'EVAL_RULE_SUCCESS'
export const EVAL_RULE_FAILURE = 'EVAL_RULE_FAILURE'
export const RESET_RULE = 'RESET_RULE'
export const RESET_CREATED_RULE = 'RESET_CREATED_RULE'

// login actions ---
export const priceRules = () => ({
  type: PRICE_RULES,
})

export const createRule = (name, canalCode, input = {}, consts = {}, rule = {}) => ({
  type: CREATE_RULE,
  payload: { name, canalCode, input, const: consts, rule },
})

export const getRuleById = (ruleId) => ({
  type: RULE_DETAILS,
  payload: { ruleId },
})
export const hideErrors = () => ({
  type: HIDE_ERRORS,
})
export const updateRule = (ruleId, data) => ({
  type: UPDATE_RULE,
  payload: { ruleId, data },
})

export const evalRule = (spec, val) => ({
  type: EVAL_RULE,
  payload: { spec, val },
})

export const resetEval = () => ({
  type: RESET_RULE,
})

export const resetCreatedRule = () => ({
  type: RESET_CREATED_RULE,
  payload: {},
})

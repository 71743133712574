import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
  Input,
  InputLabel,
  Box,
  Typography,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
// import Modal from '../Modals/ForgetPassword/ForgetPasswordModal';

import * as EmailValidator from 'email-validator'
import './ForgetPassword.scss'

export default class ForgetPassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
    }
    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
  }
  onChange(e) {
    const email = e.target.value

    this.setState({
      email,
      isValidEmail: EmailValidator.validate(email),
    })
  }
  onSubmit(e) {
    e.preventDefault()
    const { userForgetPassword } = this.props
    userForgetPassword(this.state.email)
  }

  render() {
    const { isValidEmail, email } = this.state
    // const { forgetPassword, forgetPasswordDone} = this.props;

    return (
      <div className={'form-container'}>
        <Typography component="div">
          <Box textAlign="justify" m={1}>
            {' '}
            Tu a oublié ton mot de passe ? Pas de panique on s&apos;occupe de tout !{' '}
            <br />
            Tu a juste à resneigner ton email et un nouveau mot de passe te sera
            communiqué.
          </Box>
        </Typography>
        <FormGroup>
          <FormControl>
            <InputLabel htmlFor="email-input">Email address</InputLabel>
            <Input
              type={'text'}
              id="email-input"
              aria-describedby="email-helper-text"
              name={'email'}
              onChange={this.onChange}
            />
            {email && !isValidEmail && (
              <FormHelperText id="email-helper-text" className={'error-text'}>
                Veuillez entrer une adresse e-mail valide
              </FormHelperText>
            )}
          </FormControl>
          <br />
          <br />
          <Button
            className={'primary-button'}
            disabled={!isValidEmail}
            onClick={this.onSubmit}
          >
            Envoyer
          </Button>
          <br />
        </FormGroup>
        <Button color="primary" href={'/login'}>
          <ArrowBackIcon></ArrowBackIcon>Retourner
        </Button>
      </div>
    )
  }
}
ForgetPassword.propTypes = {
  forgetPassword: PropTypes.object.isRequired,
  userForgetPassword: PropTypes.func.isRequired,
  forgetPasswordDone: PropTypes.func.isRequired,
}

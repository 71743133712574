import axios from 'axios'
import { EVAL_RULE_FAILURE } from '../rules/actions'

import { HTTP_POST, HTTP_FETCH, HTTP_PATCH, httpError, httpSuccess } from './actions'

export default (store) => (next) => (action) => {
  axios.interceptors.request.use(
    (config) => {
      config.headers = { 'X-Auth-Token': localStorage.getItem('token') }
      return config
    },
    (error) => Promise.reject(error)
  )

  // because BE returns code in body different than the response status, the error won't be catched ( ex: body: 400 , status 200)
  // ==> so I have used interceptor to reject the response if it's code >= 400
  axios.interceptors.response.use(function (response) {
    if (response.data.code >= 400) {
      return Promise.reject(response)
    }
    return response
  })
  if (action.type === HTTP_POST) {
    const { endPoint, data, success, failure } = action.payload
    axios
      .post(endPoint, data)
      .then(({ data }) => {
        store.dispatch(httpSuccess(success, data))
      })
      .catch((error) => {
        //console.log({...error})
        store.dispatch(
          httpError(
            failure,
            failure === EVAL_RULE_FAILURE ? error.response : error || {}
          )
        )
      })
  }

  if (action.type === HTTP_PATCH) {
    const { endPoint, data, success, failure } = action.payload
    axios
      .patch(endPoint, data)
      .then(({ data }) => {
        store.dispatch(httpSuccess(success, data))
      })
      .catch((error) => {
        store.dispatch(httpError(failure, error.data || {}))
      })
  }

  if (action.type === HTTP_FETCH) {
    const { endPoint, success, failure } = action.payload
    axios
      .get(endPoint)
      .then(({ data }) => store.dispatch(httpSuccess(success, data)))
      .catch((error) => {
        store.dispatch(httpError(failure, error.data || {}))
      })
  }

  next(action)
}

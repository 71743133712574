import React from 'react'
import PropTypes from 'prop-types'
import Routes from '../../routes'

export default function App({ isLoggedIn }) {
  return (
    <div className="App">
      <Routes isLoggedIn={isLoggedIn} />
    </div>
  )
}
App.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
}

export const getUserState = ({ user }) => user

export const getLoginError = (state) => getUserState(state).loginError
export const getCollaborateur = (state) => getUserState(state).collaborateur

export const getIsLoggedIn = (state) => getUserState(state).isLoggedIn
export const getIsLoading = (state) => getUserState(state).isLoading

export const getForgetPasswordStatus = (state) => {
  const { forgetPassword } = getUserState(state)

  return forgetPassword.status >= 400 ? 'error' : 'success'
}

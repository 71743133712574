import { connect } from 'react-redux'
import Login from '../pages/Login'
import { login, googleLogin } from '../redux/user/actions'
import { getLoginError, getIsLoggedIn, getIsLoading } from '../redux/user/selectors'

const mapStateToProps = (state) => ({
  loginError: getLoginError(state),
  isLoggedIn: getIsLoggedIn(state),
  isLoading: getIsLoading(state),
})

const mapDisptachToProps = (dispatch) => ({
  userLogin: (email, password) => dispatch(login(email, password)),
  userLoginGoogleAccount: (googleToken) => dispatch(googleLogin(googleToken)),
})

export default connect(mapStateToProps, mapDisptachToProps)(Login)

export default [
  { code: 'NOMBRE_COLIS', label: 'nombre de colis', type: 'var' },
  { code: 'PRIX_AU_COLIS', label: 'prix de colis', type: 'const' },
  { code: 'PRIX_ACHAT', label: `montant d'achat`, type: 'var' },
  { code: 'POIDS', label: "poids d'articles", type: 'var' },
  {
    code: 'PRIX_ADDITIONAL_KILOMETRE',
    label: 'prix de kilométres supplémentaires',
    type: 'const',
  },
  { code: 'PRIX_MANUTENTION', label: 'prix de manutention', type: 'const' },
  { code: 'MANUTENTION_ENABLED', label: 'manutention enabled', type: 'var' },
  {
    code: 'VALUE_NEXT_DAY',
    label: 'jour suppérieur au jour de livraison',
    type: 'var',
  },
  {
    code: 'DISTANCE',
    label: 'distance',
    type: 'var',
  },
  {
    code: 'PRIX_VALUE_NEXT_DAY',
    label: 'prix de jour suppérieur au jour de livraison',
    type: 'const',
  },
  { code: 'PRISE_EN_CHARGE', label: 'prix de prise en charge', type: 'var' },
]

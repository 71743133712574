import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import ReactJsonLogic from 'reactjs-json-logic'
import { Button } from 'element-react'

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 20,
    marginLeft: 150,
    marginRight: 150,
    marginBottom: 50,
  },
}))

export default function JsonLogic(props) {
  const { ruleValue, rule, input, consts } = props
  const classes = useStyles()
  const [getCurrentRule, setCurrentRule] = React.useState({})
  const [getAccessor, setAccessor] = React.useState({})
  React.useEffect(() => {
    setCurrentRule(rule)
  }, [rule])

  React.useEffect(() => {
    var mappedInput = input.map((item) => ({ [item.name]: item.value }))
    var mappedConsts = consts.map((item) => ({ [item.name]: item.value }))
    setAccessor(
      Object.assign(
        {},
        Object.assign({}, ...mappedConsts),
        Object.assign({}, ...mappedInput)
      )
    )
  }, [input, consts])

  const changedValue = (e) => {
    setCurrentRule(e)
  }
  const update = () => {
    ruleValue(getCurrentRule)
  }
  return (
    <div className={classes.root}>
      <ReactJsonLogic
        data={getAccessor}
        value={getCurrentRule}
        onChange={(e) => changedValue(e)}
      />
      <Button size="small" type="danger" onClick={() => update()}>
        Update
      </Button>
    </div>
  )
}
JsonLogic.propTypes = {
  ruleValue: PropTypes.func.isRequired,
  rule: PropTypes.object.isRequired,
  input: PropTypes.array.isRequired,
  consts: PropTypes.array.isRequired,
}

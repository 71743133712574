import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useParams, Redirect } from 'react-router-dom'
import SearchAppBar from '../../components/NavBar'
import CustomizedStepper from '../../components/Stepper'
import JsonLogic from '../../components/JsonLogic'
import Variables from '../../components/Variables'
import Consts from '../../components/Consts'
import Info from '../../containers/RuleInfo'
import Test from '../../containers/Test'
import { Button, Loading } from 'element-react'
import 'element-theme-default'

const useStyles = makeStyles(() => ({
  stepper: {
    width: '90%',
    paddingLeft: '5%',
    paddingRight: '5%',
  },
}))
export default function Rules(props) {
  const classes = useStyles()
  const {
    userLogout,
    userDetails,
    location,
    salesChannel,
    isLoading,
    getSalesChannel,
    createRule,
    hideErrors,
    errorMessage,
    newRuleCreated,
    getCreatedRule,
    getRuleById,
    updateRule,
    resetCreatedRule,
  } = props
  const { channelName, ruleId } = useParams()
  const operation = location.pathname.split('/')[1]
  const [stepItem, setStepItem] = useState(operation === 'create' ? 0 : 1)
  const nextStep = (step) => {
    let nextMove = stepItem + step
    if (nextMove < 0) nextMove = 0
    if (nextMove > 4) nextMove = 4
    setStepItem(nextMove)
  }
  const detectInputChanged = (e) => {
    console.log('inputs changed', e)
    updateRule(ruleId, { input: e })
  }
  const detectConstsChanged = (e) => {
    e.map((el) => {
      if (el.type === 'boolean') {
        el.value = el.value === 'true' ? true : false
      }
    })
    console.log('consts changed', e)
    updateRule(ruleId, { const: e })
  }
  const detectRuleChanged = (e) => {
    console.log('rule changed', e)
    updateRule(ruleId, { rule: e })
  }
  React.useEffect(() => {
    return () => {
      console.log('testing reset created rule')
      resetCreatedRule()
    }
  }, [])
  React.useEffect(() => {
    getSalesChannel()
  }, [getSalesChannel])
  React.useEffect(() => {
    if (ruleId) {
      getRuleById(ruleId)
    } else if (getCreatedRule.ruleId) {
      getRuleById(getCreatedRule.ruleId)
    }
  }, [getRuleById, newRuleCreated, ruleId])
  const validChannel = salesChannel.filter((el) => el.channel_name === channelName)
  if (validChannel.length === 0 && isLoading === false) {
    return <Redirect to="/404" />
  } else if (newRuleCreated && !ruleId) {
    return <Redirect to={`/edit/${channelName}/${getCreatedRule.ruleId}`} />
  }
  return (
    <div>
      <SearchAppBar userDetails={userDetails} userLogout={userLogout} />
      <Loading loading={isLoading}>
        <div className={classes.stepper}>
          <CustomizedStepper activeStep={stepItem} />
          {stepItem === 0 ? (
            <Info
              operation={operation}
              createRule={createRule}
              channelInfo={validChannel[0] || {}}
              errorMessage={errorMessage}
              hideErrors={hideErrors}
              getCreatedRule={getCreatedRule}
            />
          ) : null}
          {stepItem === 1 ? (
            <Variables
              ruleId={!getCreatedRule.ruleId ? '' : getCreatedRule.ruleId}
              updateRule={updateRule}
              data={!getCreatedRule.input ? [] : getCreatedRule.input}
              inputValue={(e) => detectInputChanged(e)}
              salesChannel={validChannel[0]}
            />
          ) : null}
          {stepItem === 2 ? (
            <Consts
              updateRule={updateRule}
              data={!getCreatedRule.const ? [] : getCreatedRule.const}
              constValue={(e) => detectConstsChanged(e)}
              salesChannel={validChannel[0]}
            />
          ) : null}
          {stepItem === 3 ? (
            <JsonLogic
              ruleValue={(e) => detectRuleChanged(e)}
              rule={!getCreatedRule.rule ? {} : getCreatedRule.rule}
              input={!getCreatedRule.input ? [] : getCreatedRule.input}
              consts={!getCreatedRule.const ? [] : getCreatedRule.const}
            ></JsonLogic>
          ) : null}
          {stepItem === 4 ? (
            <Test
              rule={!getCreatedRule ? {} : getCreatedRule}
              input={!getCreatedRule.input ? [] : getCreatedRule.input}
              consts={!getCreatedRule.const ? [] : getCreatedRule.const}
            />
          ) : null}
          {operation !== 'create' ? (
            <div style={{ paddingTop: '15px' }}>
              <Button onClick={() => nextStep(-1)}>
                <i className="el-icon-arrow-left el-icon-left"></i> Go Back
              </Button>
              <Button onClick={() => nextStep(1)}>
                Next step <i className="el-icon-arrow-right el-icon-right"></i>
              </Button>
            </div>
          ) : null}
        </div>
      </Loading>
    </div>
  )
}

Rules.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  userLogout: PropTypes.func.isRequired,
  userDetails: PropTypes.object.isRequired,
  salesChannel: PropTypes.array.isRequired,
  location: PropTypes.object,
  getSalesChannel: PropTypes.func.isRequired,
  getRuleById: PropTypes.func.isRequired,
  createRule: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  hideErrors: PropTypes.func.isRequired,
  newRuleCreated: PropTypes.bool.isRequired,
  getCreatedRule: PropTypes.object.isRequired,
  updateRule: PropTypes.func.isRequired,
  resetCreatedRule: PropTypes.func,
}

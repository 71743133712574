import React from 'react'
import img404 from '../../assets/images/illustration-404.svg'
import Button from '@material-ui/core/Button'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import './NotFound.scss'
export default function Index() {
  return (
    <div className="root">
      <img src={img404} alt="not-found" />
      <label>
        Désolé, la page que vous cherchez n&apos;existe pas. <br />
        Retourner à la page d’accueil
      </label>
      <Button color="primary" href="/commandes" className="button">
        <ArrowBackIcon /> Retourner
      </Button>
    </div>
  )
}

import { getValue, checkExpirationDate } from '../../utils'

export default () => {
  checkExpirationDate()

  const collaborateur = getValue('currentUser', {})
  const token = getValue('token', null)
  return {
    collaborateur,
    token,
    googleProfile: null,
    isLoggedIn: Object.entries(collaborateur).length > 0,
    isLoading: false,
    forgetPassword: {},
    loginError: null,
  }
}

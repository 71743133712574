import React from 'react'
import PropTypes from 'prop-types'

import { Redirect } from 'react-router-dom'
import {
  FormGroup,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Button,
} from '@material-ui/core'
import Loading from '../../components/Loading'

import * as EmailValidator from 'email-validator'

import './Login.scss'
import '../../assets/styles/index.scss'
import img from '../../assets/icons/corner-logo.png'

export default class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      isValidEmail: false,
      password: '',
      isValidPassword: false,
    }

    this.onChangeEmail = this.onChangeEmail.bind(this)
    this.onChangePassword = this.onChangePassword.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onChangeEmail(e) {
    const email = e.target.value

    this.setState({
      email,
      isValidEmail: EmailValidator.validate(email),
    })
  }

  onChangePassword(e) {
    const password = e.target.value

    this.setState({
      password,
      isValidPassword: password.length > 3,
    })
  }

  onSubmit(e) {
    e.preventDefault()
    const { userLogin } = this.props
    userLogin(this.state.email, this.state.password)
  }
  render() {
    const { loginError, isLoading, isLoggedIn } = this.props
    const { email, isValidEmail, isValidPassword } = this.state

    if (isLoggedIn) {
      return <Redirect to="/home" />
    }

    return (
      <>
        {isLoading && <Loading />}

        <div className="form-container">
          <img src={img} alt="corner logo" className="b2h-logo" />
          <FormGroup>
            <FormControl>
              <InputLabel htmlFor="email-input">Email address</InputLabel>
              <Input
                type={'text'}
                id="email-input"
                aria-describedby="email-helper-text"
                name={'email'}
                onChange={this.onChangeEmail}
              />
              {email && !isValidEmail && (
                <FormHelperText id="email-helper-text" className={'error-text'}>
                  Veuillez entrer une adresse e-mail valide
                </FormHelperText>
              )}
            </FormControl>
            <br />
            <FormControl>
              <InputLabel htmlFor="password-input">Mot de passe</InputLabel>
              <Input
                type={'password'}
                id="password-input"
                aria-describedby="password-helper-text"
                name={'password'}
                onChange={this.onChangePassword}
              />
              {/*{password && !isValidPassword && <FormHelperText id="password-helper-text" >We'll never share your email.</FormHelperText>}*/}
            </FormControl>
            <br />
            <Button
              variant="contained"
              className="login-button"
              disabled={!isValidEmail || !isValidPassword}
              onClick={this.onSubmit}
            >
              Se Connecter
            </Button>
            <br />
            <br />
            <Button color="primary" href={'/forgetPassword'}>
              Mot de passe oublié ?
            </Button>
          </FormGroup>
          {loginError && (
            <label
              id="loginError"
              className="form-text error-text login-error-text "
            >
              {' '}
              {loginError}{' '}
            </label>
          )}
        </div>
      </>
    )
  }
}
Login.propTypes = {
  loginError: PropTypes.string,
  userLogin: PropTypes.func.isRequired,
  userLoginGoogleAccount: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
}

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import './stepper.scss'
import { Steps } from 'element-react'

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 20,
    paddingLeft: '13%',
  },
}))

function getSteps() {
  return ['Info', 'Variables', 'Constants', 'Rule', 'Tests']
}

export default function CustomizedStepper(props) {
  const { activeStep } = props
  const classes = useStyles()
  const steps = getSteps()
  return (
    <div className={classes.root}>
      <Steps active={activeStep} finishStatus="success">
        {steps.map((label, index) => (
          <Steps.Step key={index} title={label}></Steps.Step>
        ))}
      </Steps>
    </div>
  )
}
CustomizedStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
}

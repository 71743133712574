import {
  SALES_CHANNEL,
  SALES_CHANNEL_SUCCESS,
  SALES_CHANNEL_FAILURE,
} from './actions'
import { httpFetch } from '../http/actions'

const api = process.env.REACT_APP_API_URL

export default (store) => (next) => (action) => {
  if (action.type === SALES_CHANNEL) {
    store.dispatch(
      httpFetch({
        endPoint: `${api}/canalVente/all`,
        data: action.payload,
        success: SALES_CHANNEL_SUCCESS,
        failure: SALES_CHANNEL_FAILURE,
      })
    )
  }
  next(action)
}

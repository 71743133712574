import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import './Home.scss'
import SearchAppBar from '../../components/NavBar'
import { makeStyles } from '@material-ui/core/styles'
import TableUI from '../../components/Table'
import { Loading } from 'element-react'

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: 15,
  },
  table: {
    paddingLeft: '5%',
    paddingRight: '5%',
  },
}))

export default function Home(props) {
  const classes = useStyles()

  const {
    userLogout,
    userDetails,
    salesChannel,
    getSalesChannel,
    isLoading,
    rulesIsLoading,
    priceRules,
    getPriceRules,
  } = props
  useEffect(() => {
    getSalesChannel()
  }, [getSalesChannel])
  useEffect(() => {
    getPriceRules()
  }, [getPriceRules])
  useEffect(() => {
    localStorage.setItem('rules_list', JSON.stringify(priceRules))
  }, [priceRules])
  return (
    <div className={classes.root}>
      <SearchAppBar userDetails={userDetails} userLogout={userLogout} />
      <Loading loading={isLoading}>
        <div className={classes.table}>
          <TableUI
            isLoading={isLoading && rulesIsLoading}
            salesChannel={salesChannel}
            priceRules={priceRules}
          />
        </div>
      </Loading>
    </div>
  )
}

Home.propTypes = {
  userLogout: PropTypes.func.isRequired,
  userDetails: PropTypes.object.isRequired,
  salesChannel: PropTypes.array.isRequired,
  getSalesChannel: PropTypes.func.isRequired,
  getPriceRules: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  rulesIsLoading: PropTypes.bool.isRequired,
  priceRules: PropTypes.array.isRequired,
}

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Input, Button, Message, Radio } from 'element-react'

const useStyles = makeStyles(() => ({
  testForm: {
    paddingTop: 50,
    width: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}))
export default function Tests(props) {
  const {
    input,
    evalRule,
    getEvalRule,
    isFailed,
    getEvalRuleError,
    rule,
    resetEval,
  } = props
  //console.log(input)
  const classes = useStyles()
  const formRef = React.useRef()
  const [ruleVal, setRuleVal] = React.useState({})

  const evalFunction = () => {
    var reqFields = []
    input.map((el) => {
      if (el.type === 'boolean' && ruleVal[el.name]) {
        ruleVal[el.name] = ruleVal[el.name] === 'true' ? true : false
      }
      if (el.required === 'true') {
        reqFields.push(el.name)
      }
      if (!ruleVal[el.name]) {
        ruleVal[el.name] = el.type !== 'boolean' ? '0' : false
      }
    })
    //check if user filled in all REQUIRED fields
    if (!reqFields.every((val) => Object.keys(ruleVal).includes(val))) {
      Message({
        message: 'please fill in all the required information',
        type: 'warning',
      })
    } else {
      evalRule(rule, ruleVal)
    }
  }
  const ruleValues = (field, data) => {
    input.forEach((element) => {
      if (element.name === field) {
        if (element.type === 'integer') {
          setRuleVal({ ...ruleVal, [field]: parseInt(data, 10) })
        } else if (element.type === 'float') {
          setRuleVal({ ...ruleVal, [field]: parseFloat(data) })
        } else {
          setRuleVal({ ...ruleVal, [field]: data })
        }

        console.log(ruleVal)
      }
    })
  }
  React.useEffect(() => {
    return () => {
      resetEval()
    }
  }, [])
  React.useEffect(() => {
    if (isFailed) {
      Message({ message: getEvalRuleError, type: 'warning' })
    } else if (Object.keys(getEvalRule).length !== 0) {
      Message({ message: `result is: ${String(getEvalRule.price)}`, type: 'info' })
    }
  }, [getEvalRule, isFailed, getEvalRuleError])
  return (
    <div className={classes.testForm}>
      <Form ref={formRef} labelWidth="120px">
        {input.map((el) => {
          if (el.type === 'boolean') {
            return (
              <Form.Item
                label={el.label}
                key={el.id}
                required={el.required === 'true'}
                trigger="blur"
              >
                <Radio.Group
                  size="small"
                  value={
                    ruleVal[el.name] === 'true' || ruleVal[el.name] === true
                      ? 'true'
                      : 'false'
                  }
                  onChange={(e) => ruleValues(el.name, e)}
                >
                  <Radio.Button value="true" />
                  <Radio.Button value="false" />
                </Radio.Group>
              </Form.Item>
            )
          } else {
            return (
              <Form.Item
                label={el.label}
                key={el.id}
                required={el.required === 'true'}
                trigger="blur"
              >
                <Input
                  value={ruleVal[el.name]}
                  onChange={(e) => ruleValues(el.name, e)}
                />
              </Form.Item>
            )
          }
        })}

        <Form.Item>
          <Button type="primary" onClick={() => evalFunction()}>
            Eval
          </Button>
          <span>{isFailed}</span>
        </Form.Item>
      </Form>
    </div>
  )
}
Tests.propTypes = {
  rule: PropTypes.object.isRequired,
  input: PropTypes.array.isRequired,
  consts: PropTypes.array.isRequired,
  evalRule: PropTypes.func,
  resetEval: PropTypes.func,
  getEvalRule: PropTypes.object,
  getEvalRuleError: PropTypes.string,
  isFailed: PropTypes.bool,
}

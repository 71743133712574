import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Table, Button, Input, Select, Radio } from 'element-react'
import { v4 as uuidv4 } from 'uuid'
import './style.scss'
import typeOptions from '../../constants/typeOptions'
const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 20,
  },
}))

export default function Consts(props) {
  const { data, constValue } = props

  /**
   * change data
   */
  const [inputs, setInputs] = React.useState(
    data.map((obj) => ({ ...obj, editable: false, id: uuidv4() }))
  )
  const classes = useStyles()

  function updateState(element) {
    const changedInputs = inputs.map((el) =>
      el.id === element.id ? Object.assign({}, el, element) : el
    )
    setInputs(changedInputs)
  }
  function confirmChange(data) {
    const editableTable = { ...data, editable: false }
    updateState(editableTable)
    constValue(inputs)
  }

  function editInput(data) {
    const editableTable = { ...data, editable: true }
    updateState(editableTable)
  }
  function UpdateInput(el, field, data) {
    data[field] = el
    updateState(data)
  }
  function removeInput(data) {
    const changedInputs = inputs.filter((el) => el.id !== data.id)
    setInputs(changedInputs)
    constValue(changedInputs)
  }

  function addInput() {
    const addVariable = {
      name: '',
      desc: '',
      type: '',
      value: '',
      editable: true,
      id: uuidv4(),
    }
    setInputs([...inputs, addVariable])
  }
  const columns = [
    {
      type: 'index',
    },
    {
      label: 'Name',
      prop: 'name',
      width: 220,
      render: function (data) {
        if (data.editable) {
          return (
            <Input
              placeholder="name"
              value={data.name}
              onChange={(e) => UpdateInput(e, 'name', data)}
            />
          )
        } else {
          return <span style={{ marginLeft: '10px' }}>{data.name}</span>
        }
      },
    },
    {
      label: 'Description',
      prop: 'desc',
      width: 220,
      render: function (data) {
        if (data.editable) {
          return (
            <Input
              placeholder="desc"
              value={data.desc}
              onChange={(e) => UpdateInput(e, 'desc', data)}
            />
          )
        } else {
          return <span style={{ marginLeft: '10px' }}>{data.desc}</span>
        }
      },
    },
    {
      label: 'Type',
      prop: 'type',
      width: 220,
      render: function (data) {
        if (data.editable) {
          return (
            <Select
              size="small"
              placeholder="select type"
              value={data.type}
              onChange={(e) => UpdateInput(e, 'type', data)}
            >
              {typeOptions.map((el) => {
                return (
                  <Select.Option key={el.value} label={el.label} value={el.value} />
                )
              })}
            </Select>
          )
        } else {
          return <span style={{ marginLeft: '10px' }}>{data.type}</span>
        }
      },
    },
    {
      label: 'Value',
      prop: 'value',
      width: 220,
      render: function (data) {
        if (data.editable) {
          //console.log('data :',data)
          if (data.type === 'boolean') {
            return (
              <Radio.Group
                size="small"
                value={data.value.toString()}
                onChange={(e) => UpdateInput(e, 'value', data)}
              >
                <Radio.Button value="true" />
                <Radio.Button value="false" />
              </Radio.Group>
            )
          } else {
            return (
              <Input
                placeholder="value"
                value={data.value}
                onChange={(e) => UpdateInput(e, 'value', data)}
              />
            )
          }
        } else {
          return <span style={{ marginLeft: '10px' }}>{data.value.toString()}</span>
        }
      },
    },
    {
      label: 'Operations',
      render: function (data) {
        if (data.editable) {
          return (
            <span>
              <Button
                onClick={() => confirmChange(data)}
                plain={true}
                type="info"
                size="small"
              >
                Confirm
              </Button>
              <Button onClick={() => removeInput(data)} type="danger" size="small">
                Delete
              </Button>
            </span>
          )
        } else {
          return (
            <span>
              <Button
                onClick={() => editInput(data)}
                plain={true}
                type="info"
                size="small"
              >
                Edit
              </Button>
              <Button onClick={() => removeInput(data)} type="danger" size="small">
                Delete
              </Button>
            </span>
          )
        }
      },
    },
  ]
  return (
    <div className={classes.root}>
      <Button
        type="primary"
        style={{ float: 'right', marginRight: '30px', marginBottom: '10px' }}
        size="small"
        icon="plus"
        onClick={() => addInput()}
      >
        Add
      </Button>
      <Table
        style={{ width: '100%' }}
        columns={columns}
        data={inputs}
        border={true}
        highlightCurrentRow={true}
        emptyText="No variables"
      />
    </div>
  )
}
Consts.propTypes = {
  data: PropTypes.array.isRequired, // add is required option
  constValue: PropTypes.func.isRequired,
}

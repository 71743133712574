import { connect } from 'react-redux'
import { logout } from '../redux/user/actions'
import Rules from '../pages/Rules'
import { getCollaborateur } from '../redux/user/selectors'
import { getSalesChannelDetails, isLoading } from '../redux/salesChannel/selectors'
import {
  errorMessage,
  newRuleCreated,
  getCreatedRule,
} from '../redux/rules/selectors'
import { salesChannel } from '../redux/salesChannel/actions'
import {
  createRule,
  getRuleById,
  hideErrors,
  resetCreatedRule,
  updateRule,
} from '../redux/rules/actions'

const mapStateToProps = (state) => ({
  userDetails: getCollaborateur(state),
  salesChannel: getSalesChannelDetails(state),
  isLoading: isLoading(state),
  errorMessage: errorMessage(state),
  getCreatedRule: getCreatedRule(state),
  newRuleCreated: newRuleCreated(state),
})

const mapDisptachToProps = (dispatch) => ({
  userLogout: () => dispatch(logout()),
  getSalesChannel: () => dispatch(salesChannel()),
  getRuleById: (ruleId) => dispatch(getRuleById(ruleId)),
  createRule: (name, canalCode, input, consts, rule) =>
    dispatch(createRule(name, canalCode, input, consts, rule)),
  hideErrors: () => dispatch(hideErrors()),
  updateRule: (ruleId, data) => dispatch(updateRule(ruleId, data)),
  resetCreatedRule: () => dispatch(resetCreatedRule()),
})

export default connect(mapStateToProps, mapDisptachToProps)(Rules)

import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'

export default function ProtectedRoute({ isLoggedIn, ...props }) {
  return isLoggedIn ? <Route {...props} /> : <Redirect to="/login" />
}
ProtectedRoute.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
}

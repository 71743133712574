import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Input, Button, Select } from 'element-react'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  ruleForm: {
    paddingTop: 50,
    width: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}))
const rulesList = [{ label: 'scratch', value: 'scratch' }]
export default function Info(props) {
  const { operation, createRule, channelInfo, errorMessage, getCreatedRule } = props
  const classes = useStyles()
  const { channelName } = useParams()
  const [ruleName, setRuleName] = React.useState('')
  const [selectRule, setSelectRule] = React.useState('')
  const [rules, setRules] = React.useState(rulesList)
  const priceRules = localStorage.getItem('rules_list')
  useEffect(() => {
    const existingRules = JSON.parse(priceRules)
      .filter(
        (priceRule) =>
          priceRule.const?.length > 0 &&
          priceRule.input?.length > 0 &&
          priceRule.rule
      )
      .map((rule) => ({ label: rule.name, value: rule.ruleId }))
    console.log('testing existing rules', existingRules)
    if (selectRule) {
      console.log('testing pricerules', JSON.parse(priceRules))
    }
    setRules([...existingRules, ...rulesList])
  }, [priceRules])
  // create rule function
  const createNewRule = () => {
    if (!selectRule || selectRule === 'scratch') {
      createRule(ruleName, channelInfo.channel_code)
    } else {
      const selected = JSON.parse(priceRules).find(
        (priceRule) => priceRule.ruleId === selectRule
      )
      console.log('testing selected rule ', selected)
      createRule(
        ruleName,
        channelInfo.channel_code,
        selected?.input,
        selected?.const,
        selected?.rule
      )
    }
  }
  const updateRuleName = (e) => {
    setRuleName(e)
  }
  const updateSelectedRule = (e) => {
    setSelectRule(e)
  }
  if (operation === 'create') {
    return (
      <div className={classes.ruleForm}>
        <Form labelWidth="120px">
          <Form.Item label="Rule Name" error={errorMessage}>
            <Input value={ruleName} onChange={(e) => updateRuleName(e)} />
          </Form.Item>
          <Form.Item label="From">
            <Select
              placeholder="select"
              value={selectRule}
              onChange={(e) => updateSelectedRule(e)}
            >
              {rules.map((el) => {
                return (
                  <Select.Option key={el.value} label={el.label} value={el.value} />
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Related To">
            <Input disabled={true} value={channelName} autoComplete="off" />
            <span style={{ color: 'red' }}>{errorMessage}</span>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              disabled={ruleName.length < 5 || selectRule === ''}
              onClick={() => createNewRule()}
            >
              Create
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  } else {
    return (
      <div className={classes.ruleForm}>
        <Form labelWidth="120px">
          <Form.Item label="Rule Name">
            <Input disabled={true} value={getCreatedRule.name} autoComplete="off" />
          </Form.Item>
          <Form.Item label="Rule ID">
            <Input
              disabled={true}
              value={getCreatedRule.ruleId}
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item label="Related To">
            <Input disabled={true} value={channelName} autoComplete="off" />
          </Form.Item>
        </Form>
      </div>
    )
  }
}
Info.propTypes = {
  operation: PropTypes.string.isRequired,
  createRule: PropTypes.func.isRequired,
  channelInfo: PropTypes.object.isRequired,
  errorMessage: PropTypes.string.isRequired,
  hideErrors: PropTypes.func.isRequired,
  getCreatedRule: PropTypes.object.isRequired,
}

import {
  USER_FORGET_PASSWORD,
  USER_FORGET_PASSWORD_FAILURE,
  USER_FORGET_PASSWORD_SUCCESS,
  USER_LOGIN,
  USER_LOGIN_FAILURE,
  USER_LOGIN_GOOGLE_ACCOUNT,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
} from './actions'
import { httpPost } from '../http/actions'
import { saveExpirationDate } from '../../utils'

const api = process.env.REACT_APP_API_URL

export default (store) => (next) => (action) => {
  if (action.type === USER_LOGIN) {
    store.dispatch(
      httpPost({
        endPoint: `${api}/loginCollaborateur`,
        data: action.payload,
        success: USER_LOGIN_SUCCESS,
        failure: USER_LOGIN_FAILURE,
      })
    )
  }

  if (action.type === USER_LOGIN_SUCCESS) {
    const { value, collaborateur } = action.payload.data.Response
    localStorage.setItem('currentUser', JSON.stringify(collaborateur))
    localStorage.setItem('token', value)
    saveExpirationDate()
  }
  if (action.type === USER_LOGIN_GOOGLE_ACCOUNT) {
    const { googleToken } = action.payload
    store.dispatch(
      httpPost({
        endPoint: `${api}/loginCollaborateur`,
        data: { googleToken },
        success: USER_LOGIN_SUCCESS,
        failure: USER_LOGIN_FAILURE,
      })
    )
  }
  if (action.type === USER_LOGOUT) {
    localStorage.clear()
  }
  if (action.type === USER_FORGET_PASSWORD) {
    store.dispatch(
      httpPost({
        endPoint: `${api}/resetPasswordCollaborateur`,
        data: action.payload,
        success: USER_FORGET_PASSWORD_SUCCESS,
        failure: USER_FORGET_PASSWORD_FAILURE,
      })
    )
  }

  next(action)
}

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Table, Button, Tag, Input, Radio, Select } from 'element-react'
import { v4 as uuidv4 } from 'uuid'
import './style.scss'
import typeOptions from '../../constants/typeOptions'
import definedVarAndConsts from '../../utils/definedVarAndConsts'
const VARIABLE = 'var'
export const INTEGER = 'integer'
const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 20,
  },
}))

export default function Variables({ data, inputValue, salesChannel }) {
  /**
   * change data
   */
  const [inputs, setInputs] = React.useState(
    data.map((obj) => ({ ...obj, editable: false, id: uuidv4() }))
  )
  React.useEffect(() => {
    setInputs(data.map((obj) => ({ ...obj, editable: false, id: uuidv4() })))
  }, [data])
  const classes = useStyles()

  function updateState(element) {
    const changedInputs = inputs.map((el) =>
      el.id === element.id ? Object.assign({}, el, element) : el
    )
    // return value changed to parent component
    setInputs(changedInputs)
  }
  function confirmChange(data) {
    const editableTable = { ...data, editable: false }
    updateState(editableTable)
    inputValue(inputs)
  }

  function editInput(data) {
    const editableTable = { ...data, editable: true }
    updateState(editableTable)
  }
  function UpdateInput(el, field, data) {
    data[field] = el
    if (field === 'name') {
      definedVarAndConsts.filter(({ code, label }) => {
        if (code === el) {
          data.label = label
        }
      })
      salesChannel.channel_options.filter(({ label }) => {
        if (label === el) {
          data.label = label
          data.type = INTEGER
        }
      })
    }
    updateState(data)
  }
  function removeInput(data) {
    const changedInputs = inputs.filter((el) => el.id !== data.id)
    setInputs(changedInputs)
    inputValue(changedInputs)
  }

  function addInput() {
    const addVariable = {
      name: '',
      label: '--',
      type: '',
      required: 'false',
      editable: true,
      id: uuidv4(),
    }
    setInputs([...inputs, addVariable])
  }
  const columns = [
    {
      type: 'index',
    },
    {
      label: 'Name',
      prop: 'name',
      width: 220,
      render: function (data) {
        if (data.editable) {
          return (
            <Select
              size="small"
              placeholder="select code"
              value={data.name}
              onChange={(e) => UpdateInput(e, 'name', data)}
            >
              <Select.Option
                key={'Variables_keys'}
                label="Variables"
                value="None"
                disabled
              />
              {definedVarAndConsts
                .filter((elem) => elem.type === VARIABLE)
                .map((el) => {
                  return (
                    <Select.Option key={el.code} label={el.code} value={el.code} />
                  )
                })}
              <Select.Option
                key={'options_keys'}
                label="Options"
                value="None"
                disabled
              />
              {salesChannel.channel_options.map((el) => {
                return (
                  <Select.Option key={el.id} label={el.label} value={el.label} />
                )
              })}
            </Select>
          )
        } else {
          return <span style={{ marginLeft: '10px' }}>{data.name}</span>
        }
      },
    },
    {
      label: 'Label',
      prop: 'label',
      width: 220,
      render: function (data) {
        if (data.editable) {
          return (
            <Input
              placeholder="label"
              value={data.label}
              onChange={(e) => UpdateInput(e, 'label', data)}
            />
          )
        } else {
          return <span style={{ marginLeft: '10px' }}>{data.label}</span>
        }
      },
    },
    {
      label: 'Type',
      prop: 'type',
      width: 220,
      render: function (data) {
        if (data.editable) {
          return (
            <Select
              size="small"
              placeholder="select type"
              value={data.type}
              onChange={(e) => UpdateInput(e, 'type', data)}
            >
              {typeOptions.map((el) => {
                return (
                  <Select.Option key={el.value} label={el.label} value={el.value} />
                )
              })}
            </Select>
          )
        } else {
          return <span style={{ marginLeft: '10px' }}>{data.type}</span>
        }
      },
    },
    {
      label: 'Required',
      prop: 'required',
      width: 220,
      render: function (data) {
        if (data.editable) {
          return (
            <Radio.Group
              size="small"
              value={data.required}
              onChange={(e) => UpdateInput(e, 'required', data)}
            >
              <Radio.Button value="true" />
              <Radio.Button value="false" />
            </Radio.Group>
          )
        } else {
          return <Tag type="success">{data.required}</Tag>
        }
      },
    },
    {
      label: 'Operations',
      render: function (data) {
        if (data.editable) {
          return (
            <span>
              <Button
                onClick={() => confirmChange(data)}
                plain={true}
                type="info"
                size="small"
              >
                Confirm
              </Button>
              <Button onClick={() => removeInput(data)} type="danger" size="small">
                Delete
              </Button>
            </span>
          )
        } else {
          return (
            <span>
              <Button
                onClick={() => editInput(data)}
                plain={true}
                type="info"
                size="small"
              >
                Edit
              </Button>
              <Button onClick={() => removeInput(data)} type="danger" size="small">
                Delete
              </Button>
            </span>
          )
        }
      },
    },
  ]
  return (
    <div className={classes.root}>
      <Button
        type="primary"
        style={{ float: 'right', marginRight: '30px', marginBottom: '10px' }}
        size="small"
        icon="plus"
        onClick={() => addInput()}
      >
        Add
      </Button>
      <Table
        style={{ width: '100%' }}
        columns={columns}
        data={inputs}
        border={true}
        highlightCurrentRow={true}
        emptyText="No variables"
      />
    </div>
  )
}
Variables.propTypes = {
  data: PropTypes.array.isRequired, // add is required option
  inputValue: PropTypes.func.isRequired,
  salesChannel: PropTypes.shape({
    channel_options: PropTypes.array,
  }),
}

import { connect } from 'react-redux'
import { logout } from '../redux/user/actions'
import Home from '../pages/Home'
import { getCollaborateur } from '../redux/user/selectors'
import { getSalesChannelDetails, isLoading } from '../redux/salesChannel/selectors'
import {
  getPriceRulesDetails,
  isLoading as rulesIsLoading,
} from '../redux/rules/selectors'
import { salesChannel } from '../redux/salesChannel/actions'
import { priceRules } from '../redux/rules/actions'

const mapStateToProps = (state) => ({
  userDetails: getCollaborateur(state),
  salesChannel: getSalesChannelDetails(state),
  isLoading: isLoading(state),
  rulesIsLoading: rulesIsLoading(state),
  priceRules: getPriceRulesDetails(state),
})

const mapDisptachToProps = (dispatch) => ({
  userLogout: () => dispatch(logout()),
  getSalesChannel: () => dispatch(salesChannel()),
  getPriceRules: () => dispatch(priceRules()),
})

export default connect(mapStateToProps, mapDisptachToProps)(Home)

import initialState from './initialState'
import {
  SALES_CHANNEL,
  SALES_CHANNEL_SUCCESS,
  SALES_CHANNEL_FAILURE,
} from './actions'

export default (state = initialState(), action) => {
  if (action.type === SALES_CHANNEL) {
    return {
      ...state,
      isLoading: true,
    }
  }
  if (action.type === SALES_CHANNEL_SUCCESS) {
    const { response } = action.payload.data
    const mapping = response.map(({ name, code, optionsDetails }) => ({
      channel_name: name,
      channel_code: code,
      channel_options: optionsDetails,
    }))
    return {
      ...state,
      salesChannel: mapping,
      isLoading: false,
      isFailed: false,
      errorMessage: '',
    }
  }
  if (action.type === SALES_CHANNEL_FAILURE) {
    const { ...error } = action.payload.data
    return {
      ...state,
      isLoading: false,
      isFailed: true,
      errorMessage: error.message,
    }
  }
  return state
}

export default () => {
  return {
    priceRules: [],
    isLoading: false,
    isFailed: false,
    createdRule: {},
    newRuleCreated: false,
    errorMessage: '',
    eval: {},
    evalErrorMessage: '',
  }
}

const typeOptions = [
  {
    value: 'float',
    label: 'float',
  },
  {
    value: 'double',
    label: 'double',
  },
  {
    value: 'integer',
    label: 'integer',
  },
  {
    value: 'date',
    label: 'date',
  },
  {
    value: 'string',
    label: 'string',
  },
  {
    value: 'boolean',
    label: 'boolean',
  },
]
export default typeOptions

import { connect } from 'react-redux'
import { forgetPassword, forgetPasswordDone } from '../redux/user/actions'
import ForgetPassword from '../pages/ForgetPassword'

const mapStateToProps = (state) => {
  const { forgetPassword } = state.user
  return { forgetPassword }
}

const mapDisptachToProps = (dispatch) => ({
  userForgetPassword: (email) => dispatch(forgetPassword(email)),
  forgetPasswordDone: () => dispatch(forgetPasswordDone()),
})

export default connect(mapStateToProps, mapDisptachToProps)(ForgetPassword)

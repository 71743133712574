import {
  PRICE_RULES,
  PRICE_RULES_SUCCESS,
  PRICE_RULES_FAILURE,
  CREATE_RULE,
  CREATE_RULE_SUCCESS,
  CREATE_RULE_FAILURE,
  RULE_DETAILS,
  RULE_DETAILS_SUCCESS,
  RULE_DETAILS_FAILURE,
  UPDATE_RULE,
  UPDATE_RULE_SUCCESS,
  UPDATE_RULE_FAILURE,
  EVAL_RULE,
  EVAL_RULE_SUCCESS,
  EVAL_RULE_FAILURE,
} from './actions'
import { httpFetch, httpPost, httpPatch } from '../http/actions'

const api = process.env.REACT_APP_API_URL
const priceEngineApi = process.env.REACT_APP_PRICE_ENGINE_MS

export default (store) => (next) => (action) => {
  if (action.type === PRICE_RULES) {
    store.dispatch(
      httpFetch({
        endPoint: `${api}/canalVente/priceRule`,
        success: PRICE_RULES_SUCCESS,
        failure: PRICE_RULES_FAILURE,
      })
    )
  }
  if (action.type === CREATE_RULE) {
    store.dispatch(
      httpPost({
        endPoint: `${api}/canalVente/priceRule`,
        data: action.payload,
        success: CREATE_RULE_SUCCESS,
        failure: CREATE_RULE_FAILURE,
      })
    )
  }

  if (action.type === RULE_DETAILS) {
    store.dispatch(
      httpFetch({
        endPoint: `${api}/canalVente/priceRule/${action.payload.ruleId}`,
        success: RULE_DETAILS_SUCCESS,
        failure: RULE_DETAILS_FAILURE,
      })
    )
  }

  if (action.type === UPDATE_RULE) {
    store.dispatch(
      httpPatch({
        endPoint: `${api}/canalVente/priceRule/${action.payload.ruleId}`,
        data: action.payload.data,
        success: UPDATE_RULE_SUCCESS,
        failure: UPDATE_RULE_FAILURE,
      })
    )
  }

  if (action.type === EVAL_RULE) {
    store.dispatch(
      httpPost({
        endPoint: `${priceEngineApi}/rule/eval`,
        data: { spec: action.payload.spec, data: action.payload.val },
        success: EVAL_RULE_SUCCESS,
        failure: EVAL_RULE_FAILURE,
      })
    )
  }

  next(action)
}

const checkExpirationDate = () => {
  try {
    const date = JSON.parse(localStorage.getItem('expiration_date'))
    if (!date) {
      throw new Error('Missing expiration date')
    }

    if (date < Date.now()) {
      localStorage.clear()
    }
  } catch (e) {
    localStorage.clear()
  }
}

export default checkExpirationDate
